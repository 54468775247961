<template>
  <div>
    <form @submit.prevent="submit">
      <div class="row">
        <loading-notification :show="loading" />
      </div>

      <div class="row">
        <div class="flex xs12 md4">
          <va-input
            color="info"
            v-model="form.title.value"
            :disabled="loading"
            :label="$t('layout.form.titleInput')"
            :error="!!form.title.errors.length"
            :error-messages="translatedErrors(form.title.errors)"
            @blur="validate('title')"
          >
            <va-popover
              :message="$t('layout.translation')"
              placement="top"
              slot="append"
            >
              <va-button
                flat
                small
                type="button"
                color="primary"
                style="margin-right: 0;"
                icon="entypo entypo-language"
                @click="openTranslator"
              />
            </va-popover>
          </va-input>
        </div>
        <div class="flex xs12 md3">
          <va-select
            v-model="selectedEmailType"
            :label="$t('layout.form.typeInput')"
            :options="emailTypesList"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            text-by="name"
            key-by="value"
            searchable
          />
        </div>
        <div class="flex xs12 md3">
          <va-select
            v-model="selectedLayout"
            :label="$t('layout.form.layoutInput')"
            :options="emailLayoutsList"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            text-by="name"
            key-by="value"
            searchable
          />
        </div>
        <div class="flex xs12 md2">
          <va-checkbox
            :label="$t('layout.form.localParameterInput')"
            :disabled="loading || onlyLocalParameters"
            v-model="isLocal"
          />
        </div>
      </div>

      <location-bar
        v-show="isLocal"
        flex-size="md12"
        :loading="loading"
        :with-filters="locationFilters"
        :initial-region="initialRegion"
        @selected-region="adjustRegion"
      />

      <div class="row">
        <div class="flex xs12">
          <va-collapse withBackground>
            <span slot="header">{{ $t('email_templates.variables_use') }}</span>
            <template slot="body">
              <p>{{ $t('email_template.variables_explain') }}</p>
            </template>
          </va-collapse>
        </div>
        <div class="flex xs12" v-show="selectedEmailType && selectedEmailType.variables">
          <va-collapse withBackground>
            <span slot="header">{{ $t('email_templates.variables_available') }}</span>
            <template slot="body">
              <p>
                {{ $t('email_template.variables_available_explain') }}
                <span v-for="variable of selectedEmailType.variables" :key="variable.name">
                  <br>
                  <strong v-html="`$$${variable.name}$$`"></strong>: {{ variable.desc }}
                </span>
              </p>
            </template>
          </va-collapse>
        </div>
      </div>

      <div class="row">
        <div class="flex xs12">
          <quill-editor
            class="editor"
            ref="textEditor"
            :value="content"
            :options="editorOption"
            @change="onEditorChange"
          />
        </div>
      </div>

      <div class="row">
        <div class="flex xs12">
          <va-button
            color="primary"
            :disabled="loading"
          >
            <text-loading
              :loading="loading"
              icon="fa fa-save"
            >
              {{ $t('layout.form.save') }}
            </text-loading>
          </va-button>
        </div>
      </div>
    </form>
    <va-modal
      ref="translatorModal"
      v-model="translatorModal"
      :max-width="'100vw'"
      :title="$t('layout.modals.translations')"
      :hide-default-actions="true"
      :fullscreen="true"
      @cancel="closeTranslatorModal"
    >
      <translator-modal :edit="true" :path="form.title.value" @saved="closeTranslatorModal"/>
    </va-modal>
  </div>
</template>

<script>
import { validatorMixin } from '@/services/validator'
import { mapGetters } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import debounce from 'lodash/debounce'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

const LocationBar = () => import(/* webpackPrefetch: true */ '@/components/extras/Bars/Location')
const TranslatorModal = () => import(/* webpackPrefetch: true */ '@/components/extras/TranslatorModal')

export default {
  name: 'email-templates-form',
  components: {
    quillEditor,
    LocationBar,
    TranslatorModal,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    emailTemplate: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
        }
      },
    },
  },
  mixins: [validatorMixin],
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    isNew () {
      return this.emailTemplate.id === 0
    },
    onlyLocalParameters () {
      return !this.currentUser.super
    },
    emailLayoutsList () {
      return [
        {
          name: this.$t('email.layouts.white_layout'),
          value: 1,
        },
        {
          name: this.$t('email.layouts.gray_layout'),
          value: 2,
        },
      ]
    },
    emailTypesList () {
      return [
        {
          name: this.$t('email.types.certificate'),
          value: 1,
          variables: [
            { name: 'name', desc: this.$t('email.variables.participant_name') },
            { name: 'end_date', desc: this.$t('email.variables.training_date') },
            { name: 'training', desc: this.$t('email.variables.training_type') },
          ],
        },
        {
          name: this.$t('email.types.confirm'),
          value: 2,
          variables: [
            { name: 'name', desc: this.$t('email.variables.user_name') },
            { name: 'token', desc: this.$t('email.variables.url_token') },
          ],
        },
        {
          name: this.$t('email.types.forgot'),
          value: 3,
          variables: [
            { name: 'name', desc: this.$t('email.variables.user_name') },
            { name: 'token', desc: this.$t('email.variables.url_token') },
          ],
        },
        {
          name: this.$t('email.types.message'),
          value: 4,
        },
        {
          name: this.$t('email.types.training'),
          value: 5,
          variables: [
            { name: 'end_date', desc: this.$t('email.variables.training_date') },
            { name: 'training', desc: this.$t('email.variables.training_type') },
          ],
        },
        {
          name: this.$t('email.types.inventory_request'),
          value: 6,
          variables: [
            { name: 'request', desc: this.$t('email.variables.request') },
            { name: 'user_name', desc: this.$t('email.variables.requester') },
            { name: 'team_code', desc: this.$t('email.variables.team_code') },
          ],
        },
      ]
    },
  },
  data () {
    return {
      content: '',
      isLocal: false,
      selectedName: '',
      translatorModal: false,
      locationFilters: ['regions'],
      initialRegion: null,
      selectedRegion: '',
      selectedEmailType: '',
      selectedLayout: '',
      form: {
        title: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
      },
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['clean'],
            ['link', 'image', 'video'],
          ],
        },
      },
    }
  },
  watch: {
    emailTemplate (val) {
      this.$nextTick(() => {
        this.initialData(val)
      })
    },
    currentLocale (val) {
      this.validateAll()
    },
  },
  created () {
    if (this.emailTemplate) {
      this.initialData(this.emailTemplate)
    }

    this.$nextTick(() => {
      this.validateAll()
      if (!this.currentUser.super) {
        this.isLocal = true
      }
    })
  },
  methods: {
    async initialData (val) {
      this.setFormData(val)
      if (val.content) {
        this.content = val.content
      }

      if (val.type) {
        this.selectedEmailType = this.emailTypesList.find(n => n.value === val.type)
      }

      if (val.layout) {
        this.selectedLayout = this.emailLayoutsList.find(n => n.value === val.layout)
      }

      if (val.region) {
        this.isLocal = true
        if (!this.selectedRegion) {
          this.selectedRegion = val.region
        }
        if (!this.initialRegion) {
          this.initialRegion = val.region
        }
      }

      if (val.id) {
        await this.$nextTick()
        this.validateAll()
      }
    },
    openTranslator () {
      this.translatorModal = true
    },
    closeTranslatorModal () {
      this.translatorModal = false
    },
    adjustRegion (region) {
      this.selectedRegion = region
    },
    onEditorChange: debounce(function (value) {
      this.content = value.html
    }, 466),
    async submit () {
      this.validateAll()
      if (!this.formReady) return

      const emailTemplate = this.getFormData(this.emailTemplate)
      if (this.content) {
        emailTemplate.content = this.content
      }

      if (this.selectedEmailType) {
        emailTemplate.type = this.selectedEmailType.value
      }

      if (this.selectedLayout) {
        emailTemplate.layout = this.selectedLayout.value
      }

      if (this.isLocal && this.selectedRegion) {
        emailTemplate.region_id = this.selectedRegion.id
      } else {
        emailTemplate.region_id = null
      }

      delete emailTemplate.region
      this.$emit('submit', emailTemplate)
    },
  },
}
</script>
